body {
  background-color: transparent !important;
}

a {
  color: lightblue;
}

a:hover {
  color: cyan;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lang_he {
  direction: rtl;
  text-align: right;
}

.ag-header-cell-label {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
}

.it_oauth_buttons button {
  width: 100%;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 5px;
}

.markdown-container, .markdown-container * {
  color: black;
  font-size: 16px;
}


/* .markdown-container h1,
.markdown-container h2,
.markdown-container h3 {
  font-size: 20px;
} */

.markdown-container ol {
  padding-left: 20px; /* Indent numbered lists */
  margin: 0; /* Reset any default margins */
}

.markdown-container ul {
  padding-left: 20px; /* Indent bullet lists */
  margin: 0; /* Reset any default margins */
}

.markdown-container li {
  line-height: 1.5; /* Better readability */
  word-wrap: break-word; /* Break long words to avoid overflow */
}

.markdown-container strong {
  /* color: var(--wl-highlight-color); */
  color: black;
}

.assistant_container {
  background-color: white !important;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: stretch;
  max-height: 100%;
  overflow: hidden;
}

.text_editor_container {
  display: flex;
  flex-direction: column;
  background-color: red;
  flex-grow: 1;
  overflow: hidden;
}

.chat-page {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.chat-buttons {
  position: relative;
}

.settings-panel {
  position: absolute;
  min-width: 220px;
  height: fit-content;
  right: 0px;
  bottom: 46px;
  background-color: #000;
  border-radius: 5px;
  /* background-color: black; */
  color: black;
  box-sizing: content-box;
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: smaller;
  text-align: left;
  display: flex;
  flex-direction: column;
  /* row-gap: 10px; */
}

.settings-panel .MuiSelect-select {
  color: #FFF !important;
}

.chat-container {
  /* width: 100%; */
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 5px;
  color: #000;
}

.chat-container .chatbox {
  height: 100%;
  border: 1px solid #ccc;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
  flex-grow: 2;
  max-width: 680px;
}

.chat-container .message {
  text-align: left;
  margin-bottom: 5px;
  font-size: 16px;
}

.chat-container .assistant {
  padding: 5px;
  background-color: rgb(229, 239, 255)
  ;
  border-radius: 5px;
  width: fit-content;
  max-width: 80%;
}

.chat-container .You {
  background-color:  rgba(109, 73, 254, 0.1)  ;
  width: fit-content;
  right: 0px;
  margin-right: 0px;
  margin-left: auto;
  padding: 10px;
  border-radius: 16px;
  max-width: 80%;
  min-width: 100px;
}

.chat-container .input_container {
  display: flex;
}

.chat-container .input {
  padding: 10px;
  flex-grow: 1;
}

.chat-container .button {
  padding: 10px 20px;
  margin-left: 10px;
}

.chat-container .options {
  margin-top: 4px;
  padding-left: 5px;
  font-size: smaller;
  cursor: pointer;
}

.chat-container .option {
  background-color: #ddd;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 2px;
  width: fit-content;
  color: #000;
  cursor: pointer;
}

.chat-container .links {
  color: white;
}

.wl-panel-page {
  height: 100vh;
  display: grid;
  grid-template-columns: 250px auto; /* 3 columns */
  grid-template-rows: 50px auto; /* 2 rows */
  gap: 0px; /* Space between cells */
}

.wl-panel-left, .wl-panel-logo {
  padding-left: 8px;
}

.wl-panel-left {
  overflow-y: auto;
}

.wl-panel-top {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
}

.wl-panel-center {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  overflow: hidden;
}

